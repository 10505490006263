<template>
  <div class="flex items-stretch no-wrap layout-bg">
    <LayoutDrawer
      class="sidebarWidth height-screen"
      :left-drawer-open="leftDrawerOpen"
      @click:toggle-drawer="toggleLeftDrawer"
    />

    <div class="inverseSidebarWidth">
      <LayoutHeader
        class="headerHeight"
        @click:toggle-drawer="toggleLeftDrawer"
      />
      <NuxtErrorBoundary @error="console.error">
        <main
          :class="fullWidth ? '' : 'container-xl'"
          class="column no-wrap q-pa-md inverseSidebarWidth inverseHeaderHeight overflow-auto-y overflow-hidden-x"
        >
          <LazyNuxtPage />
        </main>
        <template #error="{ error, clearError: wipeError }">
          <div padding class="bg-red text-white full-width">
            <div class="column items-start gap-sm">
              <h1 class="text-white">Oops! Something went wrong</h1>
              <div class="flex gap-sm">
                <q-btn
                  label="try again"
                  outline
                  icon="mdi-reload"
                  @click="wipeError"
                />
                <q-btn
                  label="Go Home"
                  outline
                  icon="mdi-home"
                  @click="goHome(wipeError)"
                />
              </div>
              <code>{{ error }}</code>
            </div>
          </div>
        </template>
      </NuxtErrorBoundary>
      <div
        id="sticky-spot"
        class="q-layout-padding z-top"
        style="position: sticky; padding-bottom: 0; padding-top: 0; bottom: 0"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useQuasar } from "quasar";
import { ref } from "vue";
import { navigateTo } from "#imports";

defineProps<{
  fullWidth?: boolean;
}>();

const $q = useQuasar();
const leftDrawerOpen = ref(true);

const goHome = async (wipeError: () => unknown) => {
  await navigateTo("/");
  await wipeError();
};

const toggleLeftDrawer = (e?: boolean) => {
  if (e !== undefined) {
    leftDrawerOpen.value = e;
    return;
  }

  if ($q.screen.lt.md) {
    leftDrawerOpen.value = !leftDrawerOpen.value;
  } else {
    leftDrawerOpen.value = true;
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}

$sidebarWidth: 85px;
.sidebarWidth {
  width: $sidebarWidth;
}
.inverseSidebarWidth {
  width: calc(100vw - $sidebarWidth);
}

$headerHeight: 51px;
.headerHeight {
  height: $headerHeight;
}
.inverseHeaderHeight {
  height: calc(100vh - $headerHeight);
}

.height-screen {
  height: 100vh;
}
</style>
